import React, { useEffect, useState } from 'react';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';
import ButtonBase from '~/components/button/ButtonBase';
import CollapseSection from '~/components/collapse/CollapseSection';
import TableDisplay from '~/components/table/TableDisplay';
import FormAddDetail from './FormAddDetail';
import { cloneDeep } from 'lodash';
import { IoMdTrash } from 'react-icons/io';
import { numeralCustom } from '~/utils/helpers';
import { v4 } from 'uuid';

const columns = [
  {
    name: 'Hàng hóa',
    selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
    cell: (row) => {
      return (
        <Typography>
          {row.ten_vt} ({row.ma_vt})
          {row.ma_lo && (
            <Chip
              sx={{
                backgroundColor: 'secondary.main',
                color: 'whitish.pureWhite',
              }}
              label={row.ma_lo}
              size="small"
              component="span"
            />
          )}
        </Typography>
      );
    },
    minWidth: '200px',
    wrap: true,
  },

  {
    name: 'Đơn vị tính',
    selector: (row) => row.ma_dvt,
    width: '100px',
    center: true,
  },
  {
    name: 'Giá bán',
    selector: (row) => row.gia_ban,
    format: (row) => numeralCustom(row.gia_ban).format(),
    width: '100px',
    center: true,
  },
  {
    name: 'Số lượng',
    selector: (row) => row.sl_nhap,
    width: '100px',
    center: true,
  },
  {
    name: 'Tiền hàng',
    selector: (row) => row.tien_hang,
    format: (row) => numeralCustom(row.tien_hang).format(),
    width: '100px',
    center: true,
  },
  {
    name: 'Tiền CK',
    selector: (row) => row.tien_ck,
    format: (row) => numeralCustom(row.tien_ck).format(),
    width: '100px',
    center: true,
  },
  {
    name: 'Tổng tiền',
    selector: (row) => row.tien_nhap,
    format: (row) => numeralCustom(row.tien_nhap).format(),
    width: '100px',
    right: true,
  },
];

function ChiTietSection({ show, setCollapses, hd, details, setDetails }) {
  const [openForm, setOpenForm] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClear, setToggleClear] = useState(false);

  // add a detail
  const addDetail = (detailData, isEdit = false) => {
    const { detail, ...fields } = detailData;
    const result = {
      ...fields,
      ma_vt: detail.ma_vt,
      ten_vt: detail.ten_vt,
      gia_ban_nt: fields.gia_ban,
      tien_hang_nt: fields.tien_hang,
      tien_ck_nt: fields.tien_ck,
      tien_nhap_nt: fields.tien_nhap,
    };
    if (isEdit) {
      const index = details.find((item) => item._id === detailData._id);
      const cloneDetails = cloneDeep(details);
      cloneDetails.splice(index, 1, result);
      setDetails(cloneDetails);
    } else {
      result._id = v4();
      setDetails([...details, result]);
    }
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = ({ selectedRows: _selectedRows }) => {
    setSelectedRows(_selectedRows);
  };
  // delete detail
  const handleDeleteDetail = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item.ma_vt);
    let detailsCopy = cloneDeep(details);
    detailsCopy = detailsCopy.filter((item) => !ids.includes(item.ma_vt));
    setDetails(detailsCopy);
  };

  // click on row
  const handleRowClicked = (row) => {
    setDefaultValues(row);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = () => {
    setIsEdit(false);
    setDefaultValues(null);
    setOpenForm(false);
  };

  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <>
      {openForm && (
        <FormAddDetail
          open={openForm}
          handleClose={handleCloseForm}
          addDetail={addDetail}
          defaultValues={defaultValues}
          isEdit={isEdit}
          hd={hd}
        />
      )}
      <CollapseSection
        title="Danh sách hàng trả"
        show={show}
        onCollapse={() =>
          setCollapses((prev) => ({ ...prev, chi_tiet: !prev.chi_tiet }))
        }
      >
        <Stack spacing="5px" alignItems="flex-start">
          <Stack direction="row" alignItems="center" spacing="5px">
            <ButtonBase
              variant="outlined"
              startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
              onClick={() => setOpenForm(true)}
            >
              Thêm hàng
            </ButtonBase>
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                onClick={handleDeleteDetail}
              >
                <IoMdTrash size={14} />
              </IconButton>
            )}
          </Stack>
          {details && Array.isArray(details) && details.length > 0 && (
            <TableDisplay
              columns={columns}
              data={details || []}
              onRowClicked={handleRowClicked}
              handleDelete={handleDeleteDetail}
              selectableRows
              onSelectedRowsChange={handleSelectRowsChange}
              clearSelectedRows={toggleClear}
            />
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default ChiTietSection;
