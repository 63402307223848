import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormDVT from '../../dvt/FormDVT';
import SelectInput from '~/components/input/SelectInput';
import { LOAI_VAT_TU } from '~/utils/constants';
import ImageInput from '~/components/input/ImageInput';
import useLinkImage from '~/hooks/useLinkImage';
import CheckboxInput from '~/components/input/CheckboxInput';
import { numeralCustom } from '~/utils/helpers';
import FormNVT from '../../productGroup/FormNVT';
import FormXuatXu from '../../xuatxu/FormXuatXu';

function InfoGeneralSection({
  isEdit,
  register,
  control,
  errors,
  setThumbnails,
  defaultValues,
}) {
  const generateLinkImage = useLinkImage();

  const handleFileChange = (key, file) => {
    setThumbnails((prev) => {
      return { ...prev, [key]: file };
    });
  };

  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={7}>
          <Stack gap={2}>
            <TextInput
              readOnly={isEdit}
              label="Mã hàng"
              labelWidth="25%"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_vt"
              register={register}
              errorMessage={errors?.ma_vt?.message}
            />
            <TextInput
              label="Tên hàng"
              labelWidth="25%"
              placeholder="Tên nhận dạng"
              name="ten_vt"
              required
              register={register}
              errorMessage={errors?.ten_vt?.message}
            />
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhóm"
                  apiCode="dmnvt"
                  labelWidth="25%"
                  placeholder="Nhóm"
                  searchFileds={['ten_nvt']}
                  getOptionLabel={(option) => option.ten_nvt}
                  selectedValue={value}
                  value={value || { ma_nvt: '', ten_nvt: '' }}
                  onSelect={onChange}
                  FormAdd={FormNVT}
                />
              )}
            />
            <Controller
              control={control}
              name="loai_vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  required
                  label="Loại hàng hóa"
                  labelWidth="25%"
                  placeholder="Loại hàng hóa"
                  value={value || { ma_lvt: '', ten_lvt: '' }}
                  selectedValue={value}
                  onSelect={onChange}
                  options={LOAI_VAT_TU}
                  getOptionLabel={(option) => option.ten_lvt}
                  errorMessage={errors?.loai_vat_tu?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="xuat_xu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Xuất xứ"
                  labelWidth="25%"
                  apiCode="dmxuatxu"
                  placeholder="Xuất xứ sản phẩm"
                  searchFileds={['ma_xuat_xu', 'ten_xuat_xu']}
                  getOptionLabel={(option) => option.ten_xuat_xu}
                  selectedValue={value}
                  value={value || { ma_xuat_xu: '', ten_xuat_xu: '' }}
                  onSelect={onChange}
                  FormAdd={FormXuatXu}
                />
              )}
            />
            <TextInput
              label="Vị trí"
              labelWidth="25%"
              placeholder="Chỗ đặt trong kho"
              name="vi_tri"
              register={register}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="don_vi_tinh"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Đơn vị tính"
                  labelWidth="30%"
                  apiCode="dmdvt"
                  placeholder="Đơn vị tính"
                  searchFileds={['ma_dvt', 'ten_dvt']}
                  getOptionLabel={(option) => option.ten_dvt}
                  selectedValue={value}
                  value={value || { ma_dvt: '', ten_dvt: '' }}
                  onSelect={onChange}
                  FormAdd={FormDVT}
                  errorMessage={errors?.don_vi_tinh?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="gia_mua"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá vốn"
                  placeholder="Giá vốn trung bình"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="gia_ban_le"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá gốc"
                  placeholder="Giá bán 1 đơn vị hàng hóa"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="ty_le_ck0"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Giảm giá"
                  labelWidth="30%"
                  placeholder="VD: 5"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        (%)
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="thue_suat_nk"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Thuế VAT"
                  labelWidth="30%"
                  placeholder="VD: 5"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        (%)
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <TextInput
              label="Quy cách"
              labelWidth="30%"
              placeholder="VD: Hộp x 10 cái"
              name="quy_cach"
              register={register}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={2} sx={{ mt: 1 }}>
              <ImageInput
                wrapperSx={{ width: '100px', height: '100px' }}
                url={generateLinkImage(defaultValues?.picture)}
                onChange={(file) => handleFileChange('picture', file)}
              />
              <ImageInput
                wrapperSx={{ width: '100px', height: '100px' }}
                url={generateLinkImage(defaultValues?.picture2)}
                onChange={(file) => handleFileChange('picture2', file)}
              />
              <ImageInput
                wrapperSx={{ width: '100px', height: '100px' }}
                url={generateLinkImage(defaultValues?.picture3)}
                onChange={(file) => handleFileChange('picture3', file)}
              />
            </Stack>
            <Typography sx={{ marginTop: '5px', fontStyle: 'italic' }}>
              Chọn hoặc kéo thả hình ảnh
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3} md={1.714}>
              <Controller
                control={control}
                name="hoa_don"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Có hóa đơn"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="ma_lo_yn"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Theo dõi lô"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="tg_tk"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Theo dõi tồn kho"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="banner_small"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Hàng nổi"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="hot"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Hàng HOT"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="bestseller"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Bán chạy"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.714}>
              <Controller
                control={control}
                name="newproduct"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Sản phẩm mới"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoGeneralSection;
