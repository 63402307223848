import React, { useState, useRef, useEffect } from 'react';
import { useBillContext } from '../../Bill';
import { TextField } from '@mui/material';

function SearchProduct() {
  const { setSearch } = useBillContext();
  const [searchText, setSearchText] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText);
    }, 500);
    return () => clearTimeout(timerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  return (
    <TextField
      variant="outlined"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      placeholder="Tìm mã hoặc tên hàng"
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          height: '34px',
          backgroundColor: 'common.white',
          '& .MuiInputBase-input': {
            px: 2,
            py: 1,
          },
          '& fieldset': {
            border: 'none',
          },
        },
      }}
    />
  );
}

export default SearchProduct;
