import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectApiInput from '~/components/input/SelectApiInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import AreaInput from '~/components/input/AreaInput';

const schema = yup.object({
  ten_nguoi_nhan: yup.string().required('Vui lòng nhập thông tin người nhận'),
  khach_hang: yup
    .object()
    .typeError('Vui lòng chọn khách hàng')
    .required('Vui lòng chọn khách hàng'),
  tinh_thanh: yup
    .object()
    .typeError('Vui lòng chọn tỉnh thành')
    .required('Vui lòng chọn tỉnh thành'),
  quan_huyen: yup
    .object()
    .typeError('Vui lòng chọn quận huyện')
    .required('Vui lòng chọn quận huyện'),
  xa_phuong: yup
    .object()
    .typeError('Vui lòng chọn xã phường')
    .required('Vui lòng chọn xã phường'),
  dien_thoai: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(/^[0-9]*$/, {
      message: 'Số điện thoại không hợp lệ',
    })
    .required('Vui lòng nhập số điện thoại'),
  dia_chi: yup.string().required('Vui lòng nhập địa chỉ cụ thể'),
});

const defaultData = {
  ten_nguoi_nhan: '',
  dien_thoai: '',
  khach_hang: null,
  tinh_thanh: null,
  quan_huyen: null,
  xa_phuong: null,
  dia_chi: '',
};

export default function FormThongTinGiaoHang({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ten_nguoi_nhan: defaultValues?.exfields?.ten_nguoi_nhan || '',
        khach_hang: defaultValues?.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        tinh_thanh: defaultValues?.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen: defaultValues?.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong: defaultValues?.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const tinhThanh = watch('tinh_thanh');
  const quanHuyen = watch('quan_huyen');

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const {
      ten_nguoi_nhan,
      khach_hang,
      tinh_thanh,
      quan_huyen,
      xa_phuong,
      ...fields
    } = values;

    return {
      ...fields,
      exfields: { ten_nguoi_nhan },
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      tinh_thanh: tinh_thanh?.ma_tinh_thanh || '',
      ten_tinh_thanh: tinh_thanh?.ten_tinh_thanh || '',
      quan_huyen: quan_huyen?.ma_quan_huyen || '',
      ma_quan_huyen: quan_huyen?.ma_quan_huyen || '',
      xa_phuong: xa_phuong?.ma_xa_phuong || '',
      ten_xa_phuong: xa_phuong?.ten_xa_phuong || '',
    };
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'thongtingiaohang',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="900px"
        title="thông tin giao hàng"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              register={register}
              label="Tên người nhận"
              labelWidth="30%"
              placeholder="Nhập tên người nhận"
              name="ten_nguoi_nhan"
              errorMessage={errors?.ten_nguoi_nhan?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              register={register}
              label="Điện thoại"
              labelWidth="30%"
              placeholder="Nhập số điện thoại người nhận"
              name="dien_thoai"
              errorMessage={errors?.dien_thoai?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Khách hàng"
                  labelWidth="30%"
                  placeholder="Chọn khách hàng"
                  apiCode="customer"
                  searchFileds={['ma_kh', 'ten_kh']}
                  getOptionLabel={(option) =>
                    option.ma_kh ? `${option.ten_kh} (${option.ma_kh})` : ''
                  }
                  selectedValue={value}
                  value={value || { ma_kh: '', ten_kh: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.khach_hang?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tinh_thanh"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Tỉnh / thành"
                  withIdApp={false}
                  labelWidth="30%"
                  placeholder="Chọn tỉnh / thành phố"
                  apiCode="tinhthanh"
                  searchFileds={['ten_tinh_thanh']}
                  getOptionLabel={(option) => option.ten_tinh_thanh}
                  selectedValue={value}
                  value={value || { ma_tinh_thanh: '', ten_tinh_thanh: '' }}
                  onSelect={(val) => {
                    setValue('quan_huyen', null);
                    setValue('xa_phuong', null);
                    onChange(val);
                  }}
                  errorMessage={errors?.tinh_thanh?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="quan_huyen"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Quận / huyện"
                  disabled={!tinhThanh}
                  withIdApp={false}
                  labelWidth="30%"
                  placeholder="Chọn quận huyện"
                  apiCode="quanhuyen"
                  searchFileds={['ten_quan_huyen']}
                  condition={{ ma_tinh_thanh: tinhThanh?.ma_tinh_thanh }}
                  getOptionLabel={(option) => option.ten_quan_huyen}
                  selectedValue={value}
                  value={value || { ma_quan_huyen: '', ten_quan_huyen: '' }}
                  onSelect={(val) => {
                    setValue('xa_phuong', null);
                    onChange(val);
                  }}
                  errorMessage={errors?.quan_huyen?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="xa_phuong"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  disabled={!quanHuyen}
                  label="Xã / phường"
                  withIdApp={false}
                  labelWidth="30%"
                  placeholder="Chọn xã phường"
                  apiCode="xaphuong"
                  searchFileds={['ten_xa_phuong']}
                  condition={{ ma_quan_huyen: quanHuyen?.ma_quan_huyen }}
                  getOptionLabel={(option) => option.ten_xa_phuong}
                  selectedValue={value}
                  value={value || { ma_xa_phuong: '', ten_xa_phuong: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.xa_phuong?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <AreaInput
              label="Địa chỉ cụ thể"
              labelWidth="15%"
              name="dia_chi"
              register={register}
              placeholder="Nhập số nhà, tên đường, khóm / ấp / khu phố"
              errorMessage={errors?.dia_chi?.message}
            />
          </Grid>
        </Grid>
      </ModalForm>
    </>
  );
}
