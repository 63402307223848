import HomePage from '../pages/home/HomePage';
import ChangePasswordPage from '../pages/changePassword/ChangePasswordPage';
import LoginPage from '../pages/Login/LoginPage';
import VerifyEmailPage from '../pages/register/VerifyEmailPage';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import ReportPage from '~/pages/report/ReportPage';
import Bill from '~/pages/bill/Bill';
import ProfilePage from '~/pages/profile/ProfilePage';
import TokenPage from '~/pages/devices/Divices';
import List from '~/pages/list/List';
import ListVtPage from '~/pages/list/ListVtPage';
import OrderPage from '~/pages/order/OrderPage';
import ListTinhThanhPage from '~/pages/list/ListTinhThanhPage';
import ListHd2Page from '~/pages/list/ListHd2Page';
import BkHoaDonPage from '~/pages/bkhoadon/BkHoaDonPage';
import IdAppRoute from './IdAppRoute';
import EnterprisePage from '~/pages/enterprise/EnterprisePage';
import OrderPblPage from '~/pages/order-pbl/OrderPblPage';
import ListPBLPage from '~/pages/list/ListPBLPage';

const restrictedRoutes = [
  {
    id: 'login',
    path: '/login',
    page: (
      <RestrictedRoute>
        <LoginPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-email',
    path: '/verify-email',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-code',
    path: '/verify-code',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage isForgotPassword />
      </RestrictedRoute>
    ),
  },
  {
    id: 'change-password',
    path: '/change-password',
    page: (
      <RestrictedRoute>
        <ChangePasswordPage />
      </RestrictedRoute>
    ),
  },
];
const privateRoutes = [
  {
    id: 'homepage',
    path: '/',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <HomePage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'bill',
    path: '/bill',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <Bill />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'order',
    path: '/order',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <OrderPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'order-pbl',
    path: '/order-pbl',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <OrderPblPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'report',
    path: '/report/:report',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ReportPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'profile',
    path: '/profile',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ProfilePage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'devices',
    path: '/devices',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <TokenPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'password',
    path: '/password',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ChangePasswordPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'app',
    path: '/app',
    page: (
      <PrivateRoute>
        <EnterprisePage />
      </PrivateRoute>
    ),
  },
  {
    id: 'listcode',
    path: '/list/:apicode',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <List />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'listproduct',
    path: '/list/dmvt',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ListVtPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'listproduct',
    path: '/list/tinhthanh',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ListTinhThanhPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'listhd2',
    path: '/list/hd2',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ListHd2Page />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'listpbl',
    path: '/list/pbl',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ListPBLPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'bkhaodon',
    path: '/bkhaodon',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <BkHoaDonPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
];

export { restrictedRoutes, privateRoutes };
