import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterTimeFromTo from '../FilterTimeFromTo';
import FilterSelectApi from '../FilterSelectApi';
import FilterContainer from '../FilterContainer';
import FormProduct from '~/components/form/product/FormProduct';
import FormKho from '~/components/form/kho/FormKho';
import moment from 'moment';
import { LABEL_KHO } from '~/utils/label.constant';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import FilterList from '../FilterList';

function FilterPXC({ setCondition }) {
  const originFilter = {
    so_ct: '',
    vat_tu: null,
    kho_xuat: null,
    kho_nhap: null,
    trang_thai: [],
    timeFrom: '',
    timeTo: moment().format('YYYY-MM-DD'),
  };
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);
  const [trangThais, setTrangThais] = useState([]);

  const getTrangThais = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'trangthai',
        condition: {
          q: { ma_ct: 'PXC' },
        },
        withIdApp: false,
      });
      const result = (data || []).reduce((acc, item) => {
        return [
          ...acc,
          { label: item.ten_trang_thai, value: item.ma_trang_thai },
        ];
      }, []);
      setTrangThais(result);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.so_ct) {
      condition.so_ct = filter.so_ct;
    }
    if (filter.vat_tu) {
      condition.details = {
        $elemMatch: {
          ma_vt: filter.vat_tu.ma_vt,
        },
      };
    }
    if (filter.kho_xuat) {
      condition.ma_kho_x = filter.kho_xuat.ma_kho;
    }
    if (filter.kho_nhap) {
      condition.ma_kho_n = filter.kho_nhap.ma_kho;
    }
    if ((filter.trang_thai || []).length > 0) {
      condition.trang_thai = { $in: filter.trang_thai };
    }
    if (filter.timeFrom || filter.timeTo) {
      const endDate = moment(filter.timeTo).hours(23).minutes(59).seconds(59);
      const startDate = moment(filter.timeFrom).hours(0).minutes(0).seconds(0);
      if (filter.timeFrom && filter.timeTo) {
        condition.ngay_ct = { $gte: startDate, $lte: endDate };
      } else if (filter.timeFrom) {
        condition.ngay_ct = { $gte: startDate };
      } else if (filter.timeTo) {
        condition.ngay_ct = { $lte: endDate };
      }
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  useEffect(() => {
    getTrangThais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Số chứng từ"
          placeholder="Tìm theo số chứng từ"
          value={filter.so_ct}
          onSearch={(value) => setFilter({ ...filter, so_ct: value })}
        />
        <FilterSelectApi
          title="Hàng hóa"
          apiCode="dmvt"
          placeholder="Chọn hàng hóa"
          selectedValue={filter.vat_tu}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          searchFileds={['ma_vt', 'ten_vt']}
          getOptionLabel={(option) => option.ten_vt}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
          FormAdd={FormProduct}
        />
        <FilterSelectApi
          title={`${LABEL_KHO} xuất`}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO} xuất`}
          selectedValue={filter.kho_xuat}
          value={filter.kho_xuat || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho_xuat: value })}
          FormAdd={FormKho}
        />
        <FilterSelectApi
          title={`${LABEL_KHO} nhập`}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO} nhập`}
          selectedValue={filter.kho_nhap}
          value={filter.kho_nhap || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho_nhap: value })}
          FormAdd={FormKho}
        />
        <FilterList
          title="Trạng thái"
          items={trangThais}
          defaultValues={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
        <FilterTimeFromTo
          title="Ngày chứng từ"
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPXC;
