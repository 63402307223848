import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FormProduct from '../../product/FormProduct';
import FormLo from '../../lo/FormLo';
import moment from 'moment';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    vat_tu: null,
    ma_dvt: '',
    gia_von: 0,
    ton_kho: 0,
    sl_xuat: 0,
    tien_xuat: 0,
    dien_giai: '',
    lo: null,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_dvt: defaultValues.ma_dvt,
              ten_dvt: defaultValues.ten_dvt,
              ma_lo_yn: !!defaultValues.ma_lo,
            }
          : null,
        lo: defaultValues.ma_lo
          ? { ma_lo: defaultValues.ma_lo, ten_lo: defaultValues.ten_lo }
          : null,
      }
    : defaultData;
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues ? originData : defaultData,
  });

  const vatTu = watch('vat_tu');
  const soLuongHuy = watch('sl_xuat');
  const giaVon = watch('gia_von');

  useEffect(() => {
    const tienHuy = (giaVon || 0) * (soLuongHuy || 0);
    setValue('tien_xuat', tienHuy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soLuongHuy, giaVon]);

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                readOnly={isEdit}
                label="Hàng hóa"
                required
                apiCode="dmvt"
                placeholder="Chọn hàng hóa"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={(val) => {
                  onChange(val);
                  setValue('ma_dvt', val?.ma_dvt || '');
                  setValue('gia_von', val?.gia_mua || '');
                  setValue('ton_kho', val?.ton00 || 0);
                }}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Đơn vị tính"
            labelWidth="30%"
            readOnly
            name="ma_dvt"
            register={register}
            placeholder="Đơn vị tính tự động theo hàng hóa"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="gia_von"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  label="Giá vốn"
                  labelWidth="30%"
                  readOnly
                  name="gia_von"
                  value={numeralCustom(value).format()}
                  onChange={onChange}
                  register={register}
                  placeholder="Giá vốn tự động theo hàng hóa"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="ton_kho"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  type="number"
                  label="Tồn kho"
                  labelWidth="30%"
                  readOnly
                  value={numeralCustom(value).format()}
                  onChange={onChange}
                  placeholder="Tồn kho tự động theo hàng hóa"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            type="number"
            label="Số lượng xuất"
            labelWidth="30%"
            name="sl_xuat"
            register={register}
            placeholder="Vui lòng nhập số lượng xuất"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_xuat"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  label="Tiền xuất"
                  labelWidth="30%"
                  readOnly
                  value={numeralCustom(value).format()}
                  onChange={onChange}
                  placeholder="Vui lòng nhập tiền xuất"
                />
              );
            }}
          />
        </Grid>
        {!!vatTu && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lo"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Lô - HSD"
                  apiCode="dmlo"
                  placeholder="Chọn lô"
                  searchFileds={['ma_lo', 'ten_lo']}
                  condition={{
                    ma_vt: vatTu.ma_vt,
                    han_sd: { $gt: moment().hours(0).minutes(0).seconds(0) },
                    status: true,
                  }}
                  getOptionLabel={(option) =>
                    `${option.ma_lo}${
                      option.han_sd
                        ? ` - ${formatDateDisplay(option.han_sd)}`
                        : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_lo: '', ten_lo: '' }}
                  onSelect={onChange}
                  FormAdd={FormLo}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
