import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';
import { useSelector } from 'react-redux';
import useAuthorize from '~/hooks/useAuthorize';

function Bottom() {
  const { user } = useSelector((state) => state.auth);
  const allowAuthorize = useAuthorize();
  const { saveHd } = useOrderPblContext();

  return (
    <Box sx={{ height: '42px' }}>
      <Stack
        sx={{ height: '100%', width: '100%' }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="10px"
      >
        {/* <Button
          variant="contained"
          onClick={() => saveHd(0)}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'whitish.pureWhite',
            boxShadow: 'none',
            width: '50%',
            height: '100%',
            fontSize: '14px',
            '&:hover': { backgroundColor: 'secondary.main', boxShadow: 'none' },
          }}
        >
          Đặt hàng
        </Button> */}
        {(user?.admin || allowAuthorize({ type: 'update', module: 'pbl' })) && (
          <Button
            variant="contained"
            sx={{
              color: 'whitish.pureWhite',
              width: '50%',
              height: '100%',
              boxShadow: 'none',
              fontSize: '14px',
              '&:hover': { boxShadow: 'none' },
            }}
            onClick={() => saveHd(5)}
          >
            HOÀN TẤT
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default Bottom;
