import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';
import { LABEL_KHO } from '~/utils/label.constant';

function DiaChiSection({ show, setCollapses, register }) {
  return (
    <CollapseSection
      title="Địa chỉ"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dia_chi: !prev.dia_chi }))
      }
    >
      <AreaInput
        name="dia_chi"
        register={register}
        placeholder={`Nhập địa chỉ ${LABEL_KHO}`}
      />
    </CollapseSection>
  );
}

export default DiaChiSection;
