import React, { useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import BankIcon from '~/assets/img/iconbank.png';
import BillIcon from '~/assets/img/iconBill.png';
import CustomerIcon from '~/assets/img/iconCustomer.png';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { groupBy, numeralCustom } from '~/utils/helpers';
import moment from 'moment';
import Summary from '~/components/summary/Summary';

function ResultSellToday() {
  const { asyncGetReport } = useApisContext();
  const [result, setResult] = useState({
    doanh_thu: 0,
    trai_lai: 0,
    doanh_thu_thuan: 0,
    orders: 0,
    customters: 0,
  });

  const getReport = async () => {
    const today = moment();
    const resp = await asyncGetReport({
      apiCode: 'ctbanle',
      queryObject: {
        ma_ct: 'PBL,HD7',
        tu_ngay: today
          .clone()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString(),
        den_ngay: today
          .clone()
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString(),
      },
    });

    const total = resp?.pop();
    // tính số lượng đơn hàng
    const hdToShow = (resp || []).filter(
      (item) => item.ma_ct.toLowerCase() === 'pbl'
    );
    const orders = groupBy({
      data: hdToShow,
      callbackMatch: (item) => item.so_ct,
    });
    // tính số lượng khách
    const customers = groupBy({
      data: resp,
      callbackMatch: (item) => item.ma_kh,
    });

    setResult({
      ...result,
      doanh_thu_thuan: total?.tien || 0,
      trai_lai: total?.lai || 0,
      doanh_thu: total?.tien_hang || 0,
      orders: orders?.length || 0,
      customters: customers?.length || 0,
    });
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 550, mb: '10px' }}>
        KẾT QUẢ BÁN HÀNG HÔM NAY
      </Typography>
      <Grid container spacing={2}>
        {/* Doanh thu thuan */}
        <Grid item xs={12} sm={6} md={4}>
          <Summary Icon={BankIcon} title="Doanh thu thuần">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 550,
                  color: 'secondary.main',
                }}
              >
                {numeralCustom(result?.doanh_thu_thuan).format()}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap="2px"
                sx={{
                  color: true ? 'primary.main' : 'thirdly.main',
                }}
              >
                <Typography sx={{ fontSize: '12px', color: 'error.main' }}>
                  Trả hàng: {numeralCustom(result.trai_lai).format()}
                </Typography>
              </Stack>
            </Stack>
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>
              Doanh thu: {numeralCustom(result.doanh_thu).format()}
            </Typography>
          </Summary>
        </Grid>
        {/* Don da ban */}
        <Grid item xs={12} sm={6} md={4}>
          <Summary
            Icon={BillIcon}
            title={`${result.orders} đơn hàng hoàn tất`}
            wrapperSx={{
              width: '100%',
              backgroundColor: 'secondary.fif',
              padding: '5px',
              borderRadius: '8px',
              height: '100%',
            }}
          ></Summary>
        </Grid>
        {/* Khách hàng */}
        <Grid item xs={12} sm={6} md={4}>
          <Summary
            Icon={CustomerIcon}
            title={`${result?.customters || 0} khách hàng`}
            wrapperSx={{
              width: '100%',
              backgroundColor: 'thirdly.fif',
              padding: '5px',
              borderRadius: '8px',
              height: '100%',
            }}
          ></Summary>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ResultSellToday;
