import React from 'react';
import { Chip, Stack } from '@mui/material';
import TextDatetimeInput from '~/components/input/TextDatetimeInput';
import TextSelectApiInput from '~/components/input/TextSelectApiInput';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FormNhanVien from '~/components/form/nhanvien/FormNhanVien';
import useAuthorize from '~/hooks/useAuthorize';

function Top() {
  const allowAuthorize = useAuthorize();
  const userData = useSelector((state) => state.auth.user);
  const { currentHd } = useSelector((state) => state.orderPbl);
  const { handleUpdateCurrentHd } = useOrderPblContext();

  const handleNhanVienChange = (nv) => {
    handleUpdateCurrentHd({
      ma_nv: nv?.ma_nv || '',
      ten_nv: nv?.ten_nv || '',
    });
  };

  const handleDateChange = (date) => {
    handleUpdateCurrentHd({
      ngay_ct: moment(date).format('YYYY-MM-DD HH:mm'),
    });
  };

  return (
    <Stack sx={{ height: '24px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          spacing="4px"
          sx={{ height: '24px' }}
        >
          <TextSelectApiInput
            apiCode="dmnv"
            placeholder="Tìm nhân viên"
            searchFileds={['ma_nv', 'ten_nv']}
            getOptionLabel={(option) => option.ten_nv}
            selectedValue={
              currentHd?.ma_nv
                ? {
                    ma_nv: currentHd?.ma_nv,
                    ten_nv: currentHd?.ten_nv,
                  }
                : null
            }
            onSelect={handleNhanVienChange}
            checkActive={(op) => op.ma_nv === currentHd?.ma_nv}
            renderDisplay={(props) => {
              return (
                <Chip
                  disabled={
                    userData?.admin ||
                    allowAuthorize({ type: 'update', module: 'pbl' })
                      ? false
                      : true
                  }
                  variant="filled"
                  label={
                    currentHd?.ma_nv
                      ? currentHd?.ten_nv || currentHd?.ma_nv
                      : 'Chọn nhân viên'
                  }
                  size="small"
                  sx={{
                    width: '150px',
                    borderRadius: '4px',
                    marginLeft: '0px !important',
                    '& .MuiChip-label': {
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '12px',
                    },
                  }}
                  {...props}
                />
              );
            }}
            FormAdd={FormNhanVien}
          />
        </Stack>
        <TextDatetimeInput
          defaultDate={currentHd?.ngay_ct}
          onDateChange={handleDateChange}
        />
      </Stack>
    </Stack>
  );
}

export default Top;
