import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import TextInput from '~/components/input/TextInput';
import AreaInput from '~/components/input/AreaInput';

const schema = yup.object({
  ma: yup.string().required('Vui lòng nhập mã'),
  ten: yup.string().required('Vui lòng nhập tên'),
  api_token: yup.string().required('Vui lòng nhập api token'),
});

const defaultData = {
  ma: '',
  ten: '',
  logo: '',
  api_token: '',
};

export default function FormDVVC({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    return values;
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dvvanchuyen',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="800px"
        title="đơn vị vận chuyển"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Mã"
                labelWidth="30%"
                required
                placeholder="Nhập mã đơn vị vận chuyển"
                name="ma"
                register={register}
                errorMessage={errors?.ma?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Tên"
                labelWidth="30%"
                required
                placeholder="Nhập tên đơn vị vận chuyển"
                name="ten"
                register={register}
                errorMessage={errors?.ten?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <AreaInput
                required
                labelWidth="15%"
                name="api_token"
                register={register}
                label="API Token"
                placeholder="Vui lòng nhập api token"
                errorMessage={errors?.api_token?.message}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalForm>
    </>
  );
}
