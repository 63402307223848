import React, { useState, useEffect, useMemo } from 'react';
import ButtonBase from '../button/ButtonBase';
import ModalBase from './ModalBase';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import FilterRadios from '../filter/FilterRadios';
import CheckboxInput from '../input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';

const rightMapper = {
  view: 'Xem',
  viewOfOther: 'Xem của người khác',
  add: 'Thêm mới',
  update: 'Cập nhật',
  delete: 'Xóa',
};

const systemCode = 'SC';

function AuthorizationModal({ userGroup, open, onClose }) {
  const { asyncSearchList } = useApisContext();

  // const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [rightObj, setRightObj] = useState({});
  const [group, setGroup] = useState(null);

  // handle close modal
  // const handleCloseModal = () => setOpen(false);

  // get module
  const getModule = async () => {
    const resp = await asyncSearchList({
      apiCode: 'moduleinfo',
      withIdApp: false,
      condition: { page: 1, q: { app: systemCode } },
    });
    const groupData = (resp || []).map((m) => ({
      ...m,
      label: m.title,
      value: m._id,
    }));
    setGroups(groupData);
    if (groupData.length > 0) {
      setGroup(groupData[0]);
    }
  };
  // get rights
  const getRights = async () => {
    const resp = await asyncSearchList({
      apiCode: 'right',
      condition: { page: 1, limit: 90000, q: { email: userGroup._id } },
    });
    const rightToSave = {};
    (resp || []).forEach((r) => (rightToSave[r.module] = r));
    setRightObj(rightToSave);
  };

  useEffect(() => {
    if (open) {
      getModule();
      getRights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <ModalBase
        open={open}
        handleClose={onClose}
        title={`Phân quyền: ${userGroup?.group_name}`}
        width="1000px"
      >
        <Grid container spacing="10px">
          <Grid item xs={12} md={3}>
            <Box sx={{ padding: '5px 0' }}>
              {groups?.length > 0 && (
                <FilterRadios
                  title="Nhóm"
                  values={groups}
                  defaultValue={groups[0].value}
                  onChange={(value) => {
                    const newGroup = groups.find(
                      (item) => item.value === value
                    );
                    setGroup(newGroup);
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack
              spacing="10px"
              sx={{
                padding: '5px 2px',
                maxHeight: 'calc(90vh - 20px - 39px - 39px)',
                overflow: 'auto',
              }}
            >
              {group?.items?.length > 0 &&
                group.items.map((module) => (
                  <ModuleComponent
                    key={module._id}
                    module={module}
                    rightObj={rightObj}
                    userGroup={userGroup}
                    getRights={getRights}
                  />
                ))}
            </Stack>
          </Grid>
        </Grid>
      </ModalBase>
    </>
  );
}

function ModuleComponent({ module, rightObj, userGroup, getRights }) {
  const { asyncSearchList, asyncPostData, asyncPutData } = useApisContext();
  const [, setOpenBackdrop] = useBackdropContext();

  const [trangThais, setTrangThais] = useState([]);

  const addOrUpdateRight = async ({ type, value }) => {
    setOpenBackdrop(true);
    if (!!currentRight) {
      await asyncPutData({
        apiCode: 'right',
        uniqueValue: currentRight._id,
        data: { [type]: value, email: userGroup._id, module: module.code },
      });
      await getRights();
    } else {
      await asyncPostData({
        apiCode: 'right',
        data: { [type]: value, email: userGroup._id, module: module.code },
      });
      await getRights();
    }
    setOpenBackdrop(false);
  };

  // get trang thai
  const getTrangThai = async () => {
    const resp = await asyncSearchList({
      apiCode: 'trangthai',
      withIdApp: false,
      condition: { page: 1, limit: 90000, q: { ma_ct: module.code } },
    });
    setTrangThais(resp);
  };

  const currentRight = useMemo(() => {
    const res = rightObj[module.code] || null;
    return res;
  }, [module.code, rightObj]);

  useEffect(() => {
    getTrangThai();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module.code]);

  return (
    <Paper sx={{ padding: '10px' }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        {module.title}
      </Typography>
      <Grid container>
        {module.rights.map((right) => (
          <CheckBoxComponent
            key={right}
            right={right}
            addOrUpdateRight={addOrUpdateRight}
            currentRight={currentRight}
            trangThais={trangThais}
          />
        ))}
      </Grid>
    </Paper>
  );
}

function CheckBoxComponent({
  right,
  currentRight,
  addOrUpdateRight,
  trangThais = [],
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal && (
        <ModalBase
          title="Trạng thái có quyền"
          open={openModal}
          width="500px"
          handleClose={() => setOpenModal(false)}
        >
          <Stack
            sx={{
              width: '100%',
              paddingRight: '2px',
              maxHeight: 'calc(90vh - 20px - 39px - 39px)',
              overflow: 'auto',
            }}
          >
            {trangThais?.map((trangThai) => {
              let isChecked =
                currentRight?.[`${right}_trang_thai`]?.[trangThai.stt];
              if (isChecked === undefined) {
                isChecked = true;
              }
              return (
                <CheckboxInput
                  key={trangThai._id}
                  label={trangThai.ten_trang_thai}
                  checked={isChecked}
                  onChange={(e) => {
                    addOrUpdateRight({
                      type: `${right}_trang_thai`,
                      value: {
                        ...currentRight?.[`${right}_trang_thai`],
                        [trangThai.stt]: e.target.checked,
                      },
                    });
                  }}
                />
              );
            })}
          </Stack>
        </ModalBase>
      )}
      <Grid item xs={right === 'viewOfOther' ? 3 : 2.25}>
        <Stack sx={{ width: '100%' }}>
          <CheckboxInput
            label={rightMapper[right]}
            checked={currentRight?.[right] || false}
            onChange={(e) => {
              addOrUpdateRight({ type: right, value: e.target.checked });
            }}
          />
          {currentRight?.[right] === true &&
            !!trangThais &&
            trangThais.length > 0 && (
              <ButtonBase
                variant="outlined"
                sx={{ width: 'fit-content' }}
                onClick={() => setOpenModal(true)}
              >
                Trạng thái
              </ButtonBase>
            )}
        </Stack>
      </Grid>
    </>
  );
}

export default AuthorizationModal;
