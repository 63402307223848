import React, { useState, useEffect } from 'react';
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { BiSync } from 'react-icons/bi';
import connectIndexedDB from '~/utils/indexedDB';
import { useBillContext } from '../../Bill';
import DrawerBase from '~/components/drawer/DrawerBase';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import TableBase from '~/components/table/TableBase';
import ButtonBase from '~/components/button/ButtonBase';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import './style.css';
import { MdDelete } from 'react-icons/md';
import ModalConfirm from '~/components/modal/ModalConfirm';

const columns = [
  {
    name: 'Thời gian',
    minWidth: '180px',
    selector: (row) => row.date_created,
    format: (row) =>
      formatDateDisplay(row.date_created, 'DD/MM/YYYY - HH:mm:ss'),
  },
  {
    name: 'PT thanh toán',
    selector: (row) => row.ten_pt_thanh_toan,
    minWidth: '180px',
    wrap: true,
  },
  {
    name: 'Tiền hàng',
    selector: (row) => row.t_tien_nt,
    format: (row) => numeralCustom(row.t_tien_nt).format(),
    center: true,
    minWidth: '150px',
  },
  {
    name: 'Tổng chiết khấu',
    selector: (row) => row.t_ck_nt,
    format: (row) => numeralCustom(row.t_ck_nt).format(),
    center: true,
    minWidth: '150px',
  },
  {
    name: 'Tổng thanh toán',
    selector: (row) => row.t_tt_nt,
    format: (row) => numeralCustom(row.t_tt_nt).format(),
    center: true,
    minWidth: '150px',
  },
  {
    name: 'Tiền thu',
    selector: (row) => row.tien_thu,
    format: (row) => numeralCustom(row.tien_thu).format(),
    center: true,
    minWidth: '150px',
  },
  {
    name: 'Tiền trả lại',
    selector: (row) => row.phai_tra,
    format: (row) => numeralCustom(row.phai_tra).format(),
    right: true,
    minWidth: '150px',
  },
];

function SyncPbh() {
  const alertSnackbar = useSnackbarContext();
  const { asyncPostData } = useApisContext();
  const { pbhs } = useBillContext();
  const [pbhPayments, setPbhPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [openTable, setOpenTable] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCloseTable = () => {
    setOpenTable(false);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const getPbhFromDB = async () => {
    const { getAll } = await connectIndexedDB({
      databaseName: 'pos_payment',
      storeName: 'payment',
    });
    const records = await getAll();
    if (records) {
      setPbhPayments(records);
    }
  };

  // handle sync
  const handleSync = async () => {
    if (!selectedPayments || selectedPayments.length === 0) {
      return;
    }
    setIsSyncing(true);
    handleCloseTable();
    for (let index = 0; index < selectedPayments.length; index++) {
      const item = selectedPayments[index];
      try {
        const resp = await asyncPostData({ apiCode: 'pbl', data: item });
        if (resp) {
          const { deleteOne } = await connectIndexedDB({
            databaseName: 'pos_payment',
            storeName: 'payment',
          });
          await deleteOne(item._id);
          if (index === selectedPayments.length - 1) {
            alertSnackbar('success', 'Đồng bộ hoàn tất');
          }
        } else {
          alertSnackbar('error', `Đồng bộ thất bại`);
          break;
        }
      } catch (error) {
        alertSnackbar('error', `Đồng bộ thất bại`);
        break;
      } finally {
        setIsSyncing(false);
      }
    }
    getPbhFromDB();
  };

  // handle delete
  const handleDelete = async () => {
    const { deleteOne } = await connectIndexedDB({
      databaseName: 'pos_payment',
      storeName: 'payment',
    });
    for (let i = 0; i < selectedPayments.length; i++) {
      const item = selectedPayments[i];
      await deleteOne(item._id);
    }
    getPbhFromDB();
    handleCloseConfirm();
    setSelectedPayments([]);
  };

  useEffect(() => {
    getPbhFromDB();
  }, [pbhs]);

  return (
    <>
      {showConfirm && (
        <ModalConfirm
          open={showConfirm}
          handleClose={handleCloseConfirm}
          title="Xác nhận"
          onConfirm={handleDelete}
        >
          <Typography
            sx={{ fontSize: '14px', fontStyle: 'italic', textAlign: 'center' }}
          >
            Bạn có chắc muốn xóa các hóa đơn đã chọn ?
          </Typography>
        </ModalConfirm>
      )}
      {
        <DrawerBase
          open={openTable}
          title="Hóa đơn đã thanh toán offline"
          onClose={handleCloseTable}
          anchor="top"
          zIndex={1}
        >
          <Box sx={{ maxHeight: '80vh', paddingBottom: '10px' }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing="10px"
              sx={{ padding: '5px 0' }}
            >
              <Stack direction="row" alignItems="center" spacing="5px">
                <ButtonBase
                  disabled={!selectedPayments || selectedPayments.length === 0}
                  startIcon={<BiSync />}
                  onClick={handleSync}
                >
                  Đồng bộ
                </ButtonBase>
                {selectedPayments?.length > 0 && (
                  <IconButton
                    sx={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: 'error.main',
                      color: 'whitish.pureWhite',
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: 'error.main',
                      },
                    }}
                    onClick={() => setShowConfirm(true)}
                  >
                    <MdDelete size={18} />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <TableBase
              columns={columns}
              data={pbhPayments}
              pagination={false}
              onSelectedRowsChange={(rows) => setSelectedPayments(rows)}
              fixedHeaderScrollHeight="300px"
            />
          </Box>
        </DrawerBase>
      }
      <Box sx={{ marginLeft: 'auto', marginRight: '15px' }}>
        <Tooltip
          placement="top"
          title={isSyncing ? 'Đang đồng bộ' : 'Đồng bộ hóa đơn'}
          arrow
        >
          <Badge
            badgeContent={pbhPayments?.length || 0}
            color="secondary"
            max={9}
          >
            <IconButton
              className={isSyncing ? 'spin' : ''}
              sx={{
                color: '#fff',
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
              onClick={() => !isSyncing && setOpenTable(true)}
            >
              <BiSync size="18px" />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
    </>
  );
}

export default SyncPbh;
