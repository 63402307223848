import React, { useState } from 'react';
import {
  IconButton,
  MenuItem,
  Typography,
  Menu as MuiMenu,
} from '@mui/material';
import { LuMenu } from 'react-icons/lu';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '~/redux/actions/auth.action';

const menus = [
  {
    text: 'Quản trị',
    to: '/list/pbl',
  },
];

function Menu() {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const navigate = useNavigate();
  const dispath = useDispatch();

  const handleClickItem = (path) => {
    navigate(path);
  };

  return (
    <>
      <MuiMenu
        open={!!anchorMenu}
        anchorEl={anchorMenu}
        onClose={() => setAnchorMenu(null)}
      >
        {menus.map((menu) => (
          <MenuItem
            key={menu.text}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
            onClick={() => handleClickItem(menu.to)}
          >
            <MdOutlineAdminPanelSettings size={14} />
            {menu.text}
          </MenuItem>
        ))}
        <MenuItem
          sx={{ columnGap: '10px', color: 'error.main' }}
          onClick={() => logoutUser(dispath)}
        >
          <TbLogout fontSize={14} />
          <Typography sx={{ fontSize: '14px', color: 'error.main' }}>
            Đăng xuất
          </Typography>
        </MenuItem>
      </MuiMenu>
      <IconButton
        sx={{ color: 'whitish.pureWhite' }}
        onClick={(e) => setAnchorMenu(e.currentTarget)}
      >
        <LuMenu size={20} />
      </IconButton>
    </>
  );
}

export default Menu;
