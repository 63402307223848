import React from 'react';
import { Box } from '@mui/material';

function BillLayout({ children }) {
  return (
    <Box sx={{ backgroundColor: 'primary.opacity', px: 2 }}>{children}</Box>
  );
}

export default BillLayout;
