import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Box, Button, Stack, Typography } from '@mui/material';
import { RiDiscountPercentLine } from 'react-icons/ri';
import ModalBase from '~/components/modal/ModalBase';
import DiscountIcon from '~/assets/img/discount.svg';
import { BsInfoCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { isArray, cloneDeep } from 'lodash';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';

function Discount() {
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const { currentStore } = useSelector((state) => state.store);
  const { currentHd, customer } = useSelector((state) => state.orderPbl);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState([]);

  const handleCloseModal = () => setOpenModal(false);

  const getDiscount = async () => {
    try {
      setLoading(true);
      const dateCompare = moment(currentHd?.ngay_ct).seconds(0).milliseconds(0);
      const resp = await asyncSearchList({
        apiCode: 'dmchietkhau',
        condition: {
          page: 1,
          limit: 99999,
          q: {
            $and: [
              { status: true },
              { 'exfields.loai_chiet_khau': 1 },
              {
                hieu_luc_tu: { $lte: dateCompare },
                hieu_luc_den: { $gte: dateCompare },
              },
              { $or: [{ ma_kho: currentStore?.ma_kho || '' }, { ma_kho: '' }] },
              {
                $or: [
                  {
                    ma_kh: customer?.ma_kh || '',
                    nh_kh: customer?.nh_kh || '',
                  },
                  {
                    ma_kh: customer?.ma_kh || '',
                  },
                  {
                    nh_kh: customer?.nh_kh || '',
                  },
                  {
                    ma_kh: '',
                    nh_kh: '',
                  },
                ],
              },
            ],
          },
        },
      });
      if (resp && isArray(resp)) {
        setDiscounts(resp);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore?.ma_kho, customer, currentHd?.ngay_ct]);

  return (
    <>
      <ModalBase
        title="Chiết khấu cho hóa đơn"
        width="550px"
        open={openModal}
        handleClose={handleCloseModal}
      >
        <Box
          sx={{
            maxHeight: 'calc(90vh - 20px - 39px - 39px)',
            overflow: 'auto',
            paddingRight: '4px',
          }}
        >
          <Stack spacing="5px">
            {isArray(discounts) &&
              discounts.length > 0 &&
              discounts.map((discount) => {
                const { gt_hd_tu, gt_hd_den } = discount;
                let active = true;
                if (!!gt_hd_tu && !!gt_hd_den) {
                  active =
                    Number(gt_hd_tu) <= (currentHd?.t_tien || 0) &&
                    Number(gt_hd_den) >= (currentHd?.t_tien || 0);
                } else if (!!gt_hd_tu) {
                  active = Number(gt_hd_tu) <= (currentHd?.t_tien || 0);
                } else if (gt_hd_den) {
                  active = Number(gt_hd_den) >= (currentHd?.t_tien || 0);
                }
                return (
                  <DiscountItem
                    key={discount._id}
                    discount={discount}
                    active={active}
                  />
                );
              })}
          </Stack>
        </Box>
      </ModalBase>
      <Badge color="error" badgeContent={discounts.length}>
        <LoadingButton
          loading={loading}
          disabled={discounts.length === 0}
          variant="contained"
          size="small"
          onClick={() => setOpenModal(true)}
          sx={{
            fontSize: '14px',
            color: 'whitish.pureWhite',
            backgroundColor: 'secondary.main',
            boxShadow: 'none',
            fontWeight: 400,
            textTransform: 'none',
            '&:hover': { backgroundColor: 'secondary.main', boxShadow: 'none' },
          }}
          startIcon={<RiDiscountPercentLine size={18} />}
        >
          Chiếu khấu
        </LoadingButton>
      </Badge>
    </>
  );
}

export default Discount;

function DiscountItem({ discount, active }) {
  const { currentHd } = useSelector((state) => state.orderPbl);
  const { handleUpdateCurrentHd } = useOrderPblContext();
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  const handleSelectDiscount = () => {
    const hdClone = cloneDeep(currentHd || {});
    const existed = (hdClone.exfields?.dmchietkhau || []).find(
      (ck) => ck._id === discount._id
    );
    if (existed) {
      hdClone.exfields.dmchietkhau = hdClone.exfields.dmchietkhau.filter(
        (ck) => ck._id !== discount._id
      );
    } else {
      if (!hdClone.exfields?.dmchietkhau) {
        hdClone.exfields.dmchietkhau = [];
      }
      hdClone.exfields.dmchietkhau.push(discount);
    }
    handleUpdateCurrentHd({ exfields: hdClone.exfields });
  };
  const isSelected = useMemo(() => {
    return (currentHd?.exfields?.dmchietkhau || []).some(
      (ck) => ck._id === discount._id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.exfields?.dmchietkhau]);

  return (
    <>
      <ModalBase
        title="Điều kiện áp dụng"
        width="450px"
        open={openModal}
        handleClose={handleCloseModal}
      >
        <Box
          sx={{
            maxHeight: 'calc(90vh - 20px - 39px - 39px)',
            overflow: 'auto',
            paddingRight: '4px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              border: '1px solid #ddd',
              borderRadius: '6px 6px 0 0',
            }}
          >
            <Typography
              sx={{
                width: '40%',
                textAlign: 'center',
                fontWeight: 600,
                borderRight: '1px solid #ddd',
                padding: '8px 0',
              }}
            >
              {LABEL_KHO}
            </Typography>
            <Typography
              sx={{
                width: '60%',
                textAlign: 'center',
                padding: '8px 0',
              }}
            >
              {discount.ten_kho}
            </Typography>
          </Stack>
          {(discount.ma_kh || discount.ten_kh) && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: '1px solid #ddd',
                borderTop: 'none',
              }}
            >
              <Typography
                sx={{
                  width: '40%',
                  textAlign: 'center',
                  fontWeight: 600,
                  borderRight: '1px solid #ddd',
                  padding: '8px 0',
                }}
              >
                Khách hàng
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  textAlign: 'center',
                  padding: '8px 0',
                }}
              >
                {discount.ten_kh || discount.ma_kh}
              </Typography>
            </Stack>
          )}
          {(discount.nh_kh || discount.ten_nh_kh) && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: '1px solid #ddd',
                borderTop: 'none',
              }}
            >
              <Typography
                sx={{
                  width: '40%',
                  textAlign: 'center',
                  fontWeight: 600,
                  borderRight: '1px solid #ddd',
                  padding: '8px 0',
                }}
              >
                Nhóm khách
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  textAlign: 'center',
                  padding: '8px 0',
                }}
              >
                {discount.ten_nh_kh}
              </Typography>
            </Stack>
          )}
          {(Number(discount.gt_hd_tu || 0) > 0 ||
            Number(discount.gt_hd_den || 0) > 0) && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: '1px solid #ddd',
                borderTop: 'none',
              }}
            >
              <Typography
                sx={{
                  width: '40%',
                  textAlign: 'center',
                  fontWeight: 600,
                  borderRight: '1px solid #ddd',
                  padding: '8px 0',
                }}
              >
                Giá trị hóa đơn
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  textAlign: 'center',
                  padding: '8px 0',
                  fontWeight: 600,
                }}
              >
                <Typography component="span">Từ</Typography>{' '}
                <Typography
                  component="span"
                  sx={{ color: 'error.main', fontWeight: 600 }}
                >
                  {numeralCustom(discount.gt_hd_tu).format()}đ
                </Typography>{' '}
                {Number(discount.gt_hd_den) > 0 && (
                  <>
                    <Typography component="span">Đến</Typography>{' '}
                    <Typography
                      component="span"
                      sx={{ color: 'error.main', fontWeight: 600 }}
                    >
                      {numeralCustom(discount.gt_hd_den).format()}đ
                    </Typography>
                  </>
                )}
              </Typography>
            </Stack>
          )}
        </Box>
      </ModalBase>
      <Box
        sx={{
          borderRadius: '6px',
          border: '1px dashed',
          borderColor: '#ddd',
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          sx={{
            padding: '10px',
            borderBottom: '1px dashed',
            borderColor: '#ddd',
          }}
        >
          <Stack alignItems="flex-start" sx={{ flex: 1 }}>
            {!discount.ty_le_ck ? (
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: 'error.main',
                  fontSize: '16px',
                  fontWeight: 600,
                  textWrap: true,
                }}
              >
                Giảm {numeralCustom(discount.tien_ck).format()}đ
              </Typography>
            ) : (
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  color: 'error.main',
                  fontSize: '16px',
                  fontWeight: 600,
                  textWrap: true,
                }}
              >
                Giảm {discount.ty_le_ck}%{' '}
                {discount.tien_ck
                  ? `, tối đa ${numeralCustom(discount.tien_ck).format()}đ`
                  : null}
              </Typography>
            )}
            <Typography
              sx={{
                textWrap: true,
              }}
            >
              {discount.ten_chietkhau}
            </Typography>
          </Stack>
          <Avatar
            sx={{ width: '60px', height: '60px' }}
            src={DiscountIcon}
            alt="discount-image"
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: '10px',
          }}
        >
          <Typography>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              Hạn dùng:
            </Typography>{' '}
            <Typography component="span">
              {formatDateDisplay(discount.hieu_luc_den, 'DD/MM/YYYY HH:mm')}
            </Typography>
          </Typography>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Button
              onClick={() => setOpenModal(true)}
              startIcon={<BsInfoCircle size={14} />}
            >
              Điều kiện
            </Button>
            <Button
              onClick={handleSelectDiscount}
              disabled={!active}
              variant="contained"
              sx={{
                color: 'whitish.pureWhite',
                boxShadow: 'none',
                backgroundColor: isSelected ? 'error.main' : 'primary.main',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: isSelected ? 'error.main' : 'primary.main',
                },
              }}
            >
              {isSelected ? 'Bỏ chọn' : 'Áp dụng'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
