import React, { useMemo } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import Discount from './Discount';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

function Event() {
  const { currentHd } = useSelector((state) => state.orderPbl);

  const chietkhaus = useMemo(() => {
    return currentHd?.exfields?.dmchietkhau || [];
  }, [currentHd?.exfields?.dmchietkhau]);

  return (
    <Box sx={{ padding: '0 2px' }}>
      <Paper
        sx={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ fontWeight: 600, marginBottom: '8px' }}>
          Chương trình giảm giá
        </Typography>
        <Stack direction="row">
          <Discount />
        </Stack>
        <List
          sx={{
            padding: '5px 0',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          {chietkhaus &&
            chietkhaus.length > 0 &&
            chietkhaus.map((ck) => {
              return (
                <ListItem key={ck._id} sx={{ padding: '0' }}>
                  <ListItemIcon
                    sx={{ minWidth: '25px', color: 'success.main' }}
                  >
                    <FaRegCheckCircle size={16} />
                  </ListItemIcon>
                  <ListItemText sx={{ margin: 0 }}>
                    {ck.ten_chietkhau}
                  </ListItemText>
                </ListItem>
              );
            })}
        </List>
      </Paper>
    </Box>
  );
}

export default Event;
