import React, { useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { Box, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { IoMdAddCircleOutline } from 'react-icons/io';
import MenuHover from '~/components/menu/MenuHover';
import { MdClose } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import ModalConfirm from '~/components/modal/ModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { useOrderPblContext } from '../../OrderPblPage';
import { updateHds, updateIndexHd } from '~/redux/reducrers/order-pbl.reducer';

function ListBill() {
  const { renderHds, currentHd, indexHd, hds } = useSelector(
    (state) => state.orderPbl
  );
  const { handleCreateHd } = useOrderPblContext();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState();

  const dispatch = useDispatch();

  const createHd = () => {
    handleCreateHd();
    dispatch(updateIndexHd(renderHds.length));
  };

  const handleOpenConfirm = (id) => {
    setOpenConfirm(true);
    setIdToDelete(id);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteBill = () => {
    if (!idToDelete) return;
    const newHds = hds.filter((item) => item._id !== idToDelete);
    dispatch(updateHds(newHds));
    setIdToDelete(undefined);
  };

  const handleSelectBill = (index) => {
    dispatch(updateIndexHd(index));
  };

  return (
    <>
      <ModalConfirm
        title="Xác nhận"
        open={openConfirm}
        handleClose={handleCloseConfirm}
        onConfirm={handleDeleteBill}
      >
        <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
          Bạn có chắc muốn xóa hóa đơn này không ?
        </Typography>
      </ModalConfirm>
      <Stack direction="row" spacing="2px" alignItems="center">
        {!!currentHd && (
          <Box
            sx={{
              position: 'relative',
              width: '100px',
              height: '34px',
              backgroundColor: 'whitish.pureWhite',
              color: 'initial',
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '14px',
              padding: '0 10px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              cursor: 'pointer',
              '&:hover': {
                '& .order-delete': {
                  opacity: 1,
                },
              },
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>
              Hóa đơn {indexHd + 1}
            </Typography>
            <IconButton
              className="order-delete"
              onClick={() => handleOpenConfirm(currentHd._id)}
              sx={{
                opacity: 0,
                position: 'absolute',
                zIndex: 1,
                right: '2px',
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'error.main',
                padding: '3px',
                color: 'whitish.pureWhite',
                transition: 'all linear 0.1s',
                '&:hover': {
                  backgroundColor: 'error.main',
                },
              }}
            >
              <MdClose size={12} />
            </IconButton>
          </Box>
        )}
        {renderHds?.length > 1 && (
          <MenuHover
            content={
              <Stack
                sx={{ minWidth: '150px', maxHeight: '200px', overflow: 'auto' }}
              >
                {renderHds.map((hd, index) => {
                  return (
                    <MenuItem
                      key={index}
                      sx={{
                        justifyContent: 'space-between',
                        backgroundColor:
                          index === indexHd ? 'primary.opacity' : '',
                        color: index === indexHd ? 'whitish.pureWhite' : '',
                        '&:hover': {
                          backgroundColor:
                            index === indexHd ? 'primary.opacity' : '',
                          '& .order-delete': {
                            opacity: 1,
                          },
                        },
                      }}
                      onClick={() => {
                        if (index !== indexHd) {
                          handleSelectBill(index);
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: '14px' }}>
                        Hóa đơn {index + 1}
                      </Typography>
                      <IconButton
                        className="order-delete"
                        sx={{
                          color: 'error.main',
                          opacity: 0,
                          transition: 'all linear 0.1s',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenConfirm(hd._id);
                        }}
                      >
                        <BsTrash size={13} />
                      </IconButton>
                    </MenuItem>
                  );
                })}
              </Stack>
            }
          >
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                color: 'whitish.pureWhite',
                // backgroundColor: 'whitish.pureWhite',
                '&:hover': {
                  // backgroundColor: 'whitish.pureWhite',
                },
              }}
            >
              <TiArrowSortedDown size={14} />
            </IconButton>
          </MenuHover>
        )}
        <IconButton onClick={createHd} sx={{ color: 'whitish.pureWhite' }}>
          <IoMdAddCircleOutline size={16} />
        </IconButton>
      </Stack>
    </>
  );
}

export default ListBill;
