import React, { useEffect, useMemo, useState } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import moment from 'moment';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { groupBy } from '~/utils/helpers';
import { isArray } from 'lodash';

function DoanhThuThang() {
  const theme = useTheme();
  const showAlert = useAlertContext();
  const { asyncGetReport } = useApisContext();
  const [data, setData] = useState([]);

  const getReport = async () => {
    try {
      const today = moment();
      const resp = await asyncGetReport({
        apiCode: 'ctbanle',
        queryObject: {
          ma_ct: 'pbl',
          tu_ngay: today.clone().startOf('month').toISOString(),
          den_ngay: today
            .clone()
            .endOf('month')
            .hours(23)
            .minutes(59)
            .seconds(59)
            .milliseconds(999)
            .toISOString(),
        },
      });
      resp.pop();
      const days = groupBy({
        data: resp,
        callbackMatch: (item) => item.ngay,
        returnObject: true,
      });
      const numberDayOfMonth = moment().daysInMonth();

      const result = [];
      for (let i = 1; i <= numberDayOfMonth; i++) {
        if (days[i] && isArray(days[i]) && days[i].length > 0) {
          const doanhThu = days[i].reduce((acc, item) => {
            return acc + item.tien;
          }, 0);
          result.push({
            day: i,
            doanh_thu: doanhThu,
          });
        } else {
          result.push({ day: i, doanh_thu: 0 });
        }
      }
      setData(result);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lỗi khi tải báo cáo',
      });
    }
  };

  const showChart = useMemo(() => {
    return (data || []).some((item) => item.doanh_thu > 0);
  }, [data]);

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 550, mb: '10px' }}>
        DOANH THU THÁNG NÀY
      </Typography>
      <Box>
        {!showChart ? (
          <Typography sx={{ py: 2, textAlign: 'center' }}>
            Chưa có doanh thu
          </Typography>
        ) : (
          <BarChart
            dataset={data}
            xAxis={[
              { scaleType: 'band', dataKey: 'day', tickPlacement: 'middle' },
            ]}
            series={[
              {
                dataKey: 'doanh_thu',
                label: 'Doanh thu',
                color: theme.palette.primary.main,
              },
            ]}
            margin={{ top: 50, bottom: 30, left: 80, right: 0 }}
            borderRadius={4}
            height={500}
          />
        )}
      </Box>
    </Paper>
  );
}

export default DoanhThuThang;
