import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const orderPblSlice = createSlice({
  name: 'orderPbl',
  initialState: {
    hds: [],
    renderHds: [],
    indexHd: 0,
    currentHd: null,
    customer: null,
  },
  reducers: {
    createHd(state, action) {
      state.hds.push(action.payload);
    },
    updateHds(state, action) {
      state.hds = action.payload;
    },
    updateOneHd(state, action) {
      const { payload } = action;
      const newHd = cloneDeep(payload);
      const index = state.hds.findIndex((hd) => hd._id === newHd._id);
      if (index < 0) return;
      let tongTienHang = 0;
      let tongTienCk = 0;
      let tienCkHd = 0;

      for (let i = 0; i < newHd.details.length; i++) {
        const detail = cloneDeep(newHd.details[i]);
        // tinh toan detail
        detail.tien_nt = (detail?.gia_ban_nt || 0) * (detail.sl_xuat || 0);
        detail.tien = detail.tien_nt;
        detail.tien_xuat_nt = detail.tien_nt - detail.tien_ck_nt;
        detail.so_luong = detail.sl_xuat;
        detail.tien_hang = detail.tien_nt;
        detail.tien_hang_nt = detail.tien_hang;
        newHd.details[i] = detail;
        // tinh tong cho hd2
        tongTienHang += detail.tien_nt || 0;
        tongTienCk += detail.tien_ck_nt || 0;
      }
      // Nếu tiền hàng thay đổi hoặc khách hàng thay đổi => reset chiết khấu hóa đơn, exfields, pttt
      if (
        tongTienHang !== (state.currentHd?.t_tien_nt || 0) ||
        newHd.ma_kh !== state.currentHd?.ma_kh
      ) {
        newHd.tien_ck_hd = 0;
        newHd.ty_le_ck_hd = 0;
        newHd.pt_thanh_toan = '';
        newHd.exfields = {};
      }
      // tính tiền ck áp dụng chương trình giảm giá, nếu có áp dụng chương trình
      const chietkhaus = newHd?.exfields?.dmchietkhau || [];
      if (chietkhaus.length > 0) {
        tienCkHd = chietkhaus.reduce((acc, item) => {
          if ((item.ty_le_ck_hd || 0) > 0) {
            let tienCk = ((item.ty_le_ck_hd || 0) * tongTienHang) / 100;
            tienCk = tienCk > item.tien_ck ? item.tien_ck : tienCk;
            acc += tienCk;
            return acc;
          } else {
            acc += item.tien_ck || 0;
            return acc;
          }
        }, 0);
        // không áp dụng chiết khấu hóa đơn thông thường
        const tyLeCkPttt = newHd?.exfields?.ty_le_ck_pttt || 0;
        newHd.ty_le_ck_hd = tyLeCkPttt;
      }
      // tính tiền ck khi có tỷ lệ ck hóa đơn
      if (newHd?.ty_le_ck_hd > 0) {
        tienCkHd += ((newHd?.ty_le_ck_hd || 0) * tongTienHang) / 100;
      }

      newHd.tien_ck_hd = tienCkHd;
      tongTienCk += newHd.tien_ck_hd || 0;
      const tongThanhToan = tongTienHang - tongTienCk;
      state.hds.splice(index, 1, {
        ...newHd,
        t_tien: tongTienHang,
        t_tien_nt: tongTienHang,
        t_ck: tongTienCk,
        t_ck_nt: tongTienCk,
        t_tt: tongThanhToan,
        t_tt_nt: tongThanhToan,
      });
    },
    initRenderHds(state, action) {
      state.renderHds = action.payload;
    },
    updateCurrentHd(state, action) {
      state.currentHd = action.payload;
    },
    updateIndexHd(state, action) {
      state.indexHd = action.payload;
    },
    updateCustomer(state, action) {
      state.customer = action.payload;
    },
    resetHd(state) {
      state.hds = [];
      state.renderHds = [];
      state.indexHd = 0;
      state.currentHd = null;
      state.customer = null;
    },
  },
});

export const {
  updateIndexHd,
  updateCurrentHd,
  updateHds,
  updateCustomer,
  updateOneHd,
  createHd,
  initRenderHds,
  resetHd,
} = orderPblSlice.actions;
export default orderPblSlice.reducer;
