import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import FilterRadios from '~/components/filter/FilterRadios';
import { useSelector } from 'react-redux';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';

function Dvvanchuyen() {
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const { currentHd } = useSelector((state) => state.orderPbl);
  const { handleUpdateCurrentHd } = useOrderPblContext();
  const [data, setData] = useState([]);

  const handleDvvanchuyenChange = (maDvvc) => {
    handleUpdateCurrentHd({
      dich_vu_giao_hang: maDvvc,
    });
  };

  const getData = async () => {
    try {
      const resp = await asyncGetList({
        apiCode: 'dvvanchuyen',
        condition: { page: 1, limit: 99999 },
      });
      if (isArray(resp)) {
        setData(resp);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message:
          error?.message || error?.error || 'Lỗi khi tải đơn vị vận chuyển',
      });
    }
  };

  const dvvcs = useMemo(() => {
    if (!data) {
      return [];
    }
    const result = data.reduce((acc, item) => {
      return [...acc, { label: item.ten, value: item.ma, ...item }];
    }, []);
    return result;
  }, [data]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ padding: '0 2px' }}>
      <FilterRadios
        title="Đơn vị vận chuyển"
        values={dvvcs}
        showActive={false}
        defaultValue={currentHd?.dich_vu_giao_hang || ''}
        onChange={handleDvvanchuyenChange}
      />
    </Box>
  );
}

export default Dvvanchuyen;
