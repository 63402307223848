import React from 'react';
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { AiOutlineSetting } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { logoutUser } from '~/redux/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalTheme } from '~/context/themeContext';
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import useLinkImage from '~/hooks/useLinkImage';
import MenuHover from '~/components/menu/MenuHover';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { internals, systems } from '~/utils/menu';
import useAuthorize from '~/hooks/useAuthorize';
import { BsFillBuildingFill } from 'react-icons/bs';

function HeaderActions({ isAdmin }) {
  const allowAuthorize = useAuthorize();
  const authData = useSelector((state) => state.auth);
  const generateLinkImage = useLinkImage();
  const [, setThemeId] = useGlobalTheme();
  const dispath = useDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Button
        onClick={() => navigate('/bill')}
        variant="contained"
        color="primary"
        sx={{ color: 'common.white', textTransform: 'none' }}
      >
        Bán hàng
      </Button>
      {/* <Themes /> */}
      {/* Website */}
      {/* <MenuHover
        content={
          <List sx={{ minWidth: '200px' }}>
            {websites.map((item, index) => {
              if (!allowAuthorize({ type: 'view', module: item.module })) {
                return null;
              }
              return (
                <ListItemButton
                  key={`${item.module}-${index}`}
                  sx={{ px: 1, py: 0.5 }}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemText sx={{ fontSize: '14px' }}>
                    {item.text}
                  </ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        }
      >
        <Button
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            textTransform: 'none',
            color: 'initial',
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
          endIcon={<CgWebsite size={14} />}
        >
          Website
        </Button>
      </MenuHover> */}
      {/* Nội bộ */}
      {allowAuthorize({
        type: 'view',
        module: internals.map((inter) => inter.module),
      }) && (
        <MenuHover
          content={
            <Stack sx={{ minWidth: '200px' }}>
              {internals.map((item, index) => {
                if (!allowAuthorize({ type: 'view', module: item.module })) {
                  return null;
                }
                return (
                  <MenuItem
                    key={`${item.module}-${index}`}
                    sx={{ columnGap: '10px' }}
                    onClick={() => navigate(item.path)}
                  >
                    <Typography sx={{ fontSize: '14px' }}>
                      {item.text}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Stack>
          }
        >
          <Button
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textTransform: 'none',
              color: 'initial',
              '&:hover': {
                backgroundColor: 'unset',
              },
            }}
            endIcon={<HiOutlineUserGroup size={14} />}
          >
            Nội bộ
          </Button>
        </MenuHover>
      )}
      {/* Hệ thống */}
      {allowAuthorize({
        type: 'view',
        module: systems.map((sys) => sys.module),
      }) && (
        <MenuHover
          content={
            <Stack sx={{ minWidth: '200px' }}>
              {systems.map((item) => {
                if (!allowAuthorize({ type: 'view', module: item.module }))
                  return null;
                return (
                  <MenuItem
                    key={item.module}
                    sx={{ columnGap: '10px' }}
                    onClick={() => navigate(item.path)}
                  >
                    <Typography sx={{ fontSize: '14px' }}>
                      {item.text}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Stack>
          }
        >
          <Button
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textTransform: 'none',
              color: 'initial',
              '&:hover': {
                backgroundColor: 'unset',
              },
            }}
            endIcon={<AiOutlineSetting size={14} />}
          >
            Hệ thống
          </Button>
        </MenuHover>
      )}
      {/* Thông báo */}
      <Notification />
      {/* User */}
      <MenuHover
        content={
          <Stack sx={{ minWidth: '200px' }}>
            <MenuItem
              sx={{ columnGap: '10px' }}
              onClick={() => navigate('/profile')}
            >
              <FaUserCircle fontSize={14} />
              <Typography sx={{ fontSize: '14px' }}>Tài khoản</Typography>
            </MenuItem>
            <MenuItem
              sx={{ columnGap: '10px' }}
              onClick={() => navigate('/app')}
            >
              <BsFillBuildingFill fontSize={14} />
              <Typography sx={{ fontSize: '14px' }}>
                Danh sách công ty
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ columnGap: '10px', color: 'error.main' }}
              onClick={() => logoutUser(dispath, setThemeId)}
            >
              <TbLogout fontSize={14} />
              <Typography sx={{ fontSize: '14px', color: 'error.main' }}>
                Đăng xuất
              </Typography>
            </MenuItem>
          </Stack>
        }
      >
        <Button
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            textTransform: 'none',
            color: 'initial',
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
          endIcon={
            <Avatar
              src={generateLinkImage(authData?.profile?.picture)}
              sx={{ width: '20px', height: '20px' }}
            />
          }
        >
          {authData?.profile?.email || 'email@gmail.com'}
        </Button>
      </MenuHover>
    </Box>
  );
}

export default HeaderActions;
