import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import TextInput from '../input/TextInput';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import ButtonBase from '../button/ButtonBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import CheckboxInput from '../input/CheckboxInput';
import { useNavigate } from 'react-router-dom';
import useAuthorize from '~/hooks/useAuthorize';
import { useSelector } from 'react-redux';
import AuthorizationModal from '../modal/AuthorizationModal';

function ExpandUserGroup({ data, openForm, setLoad }) {
  const allowAuthorize = useAuthorize();
  const userData = useSelector((state) => state.auth.user);
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete } = useApisContext();
  const navigate = useNavigate();
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({
        apiCode: 'usergroup',
        uniqueValue: data._id,
      });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <>
      <AuthorizationModal
        open={openAuthModal}
        onClose={() => setOpenAuthModal(false)}
        userGroup={data}
      />
      <Box
        sx={{
          padding: '20px',
          backgroundColor: 'whitish.graySoft',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '0 0 4px 4px',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={8}>
            <Stack spacing="20px">
              <TextInput
                readOnly
                labelWidth="40%"
                label="Tên nhóm"
                value={data.group_name}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày tạo"
                readOnly
                value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người tạo"
                value={data.user_created}
              />
              <DatetimeInput
                labelWidth="40%"
                label="Ngày cập nhật cuối"
                readOnly
                value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
              />
              <TextInput
                readOnly
                labelWidth="40%"
                label="Người cập nhật cuối"
                value={data.user_updated}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing="20px">
              <CheckboxInput
                label="Là nhóm khách hàng"
                checked={data.is_customer_group}
              />
              <CheckboxInput
                label="Là nhóm nhân viên"
                checked={data.is_employee_group}
              />
              <CheckboxInput
                label="Là nhóm cộng tác viên"
                checked={data.is_partner_group}
              />
              <CheckboxInput label="Còn sử dụng" checked={data.status} />
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing="10px"
          sx={{ marginTop: '20px' }}
        >
          {userData?.admin && (
            <ButtonBase
              sx={{
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.main' },
              }}
              onClick={() => setOpenAuthModal(true)}
            >
              Phân quyền
            </ButtonBase>
          )}
          {allowAuthorize({ type: 'view', module: 'participant' }) && (
            <ButtonBase
              sx={{
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.main' },
              }}
              onClick={() =>
                navigate('/list/participant', {
                  state: { userGroup: data },
                })
              }
            >
              Xem người dùng
            </ButtonBase>
          )}
          {allowAuthorize({ type: 'update', module: 'usergroup' }) && (
            <ButtonBase
              sx={{
                backgroundColor: 'secondary.main',
                '&:hover': { backgroundColor: 'secondary.main' },
              }}
              onClick={openForm}
            >
              Chỉnh sửa
            </ButtonBase>
          )}
          {allowAuthorize({ type: 'delete', module: 'usergroup' }) && (
            <ButtonBase
              sx={{
                backgroundColor: 'error.main',
                '&:hover': { backgroundColor: 'error.main' },
              }}
              onClick={() => {
                showConfirm({
                  title: 'Xác nhận xóa',
                  content: (
                    <Box sx={{ padding: '0 10px' }}>
                      <Typography
                        sx={{ fontSize: '14px', textAlign: 'center' }}
                      >
                        Bạn có chắc muốn xóa dòng này không ?
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          textAlign: 'center',
                          fontStyle: 'italic',
                          color: 'primary.main',
                          marginTop: '10px',
                        }}
                      >
                        Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                      </Typography>
                    </Box>
                  ),
                  onConfirm: handleDelete,
                });
              }}
            >
              Xóa
            </ButtonBase>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default ExpandUserGroup;
