import React from 'react';
import { Box, Grid } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { Controller } from 'react-hook-form';
import DatetimeInput from '~/components/input/DatetimeInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import { LABEL_KHO } from '~/utils/label.constant';
import FormKho from '../../kho/FormKho';

function InfoSection({ register, control, errors }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              type="text"
              label="Số chứng từ"
              placeholder="Nhập hoặc tạo tự động"
              name="so_ct"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="ngay_ct"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Ngày chứng từ"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.ngay_ct?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="kho_xuat"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label={`${LABEL_KHO} xuất`}
                  required
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO} xuất`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho_xuat?.message}
                  FormAdd={FormKho}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="kho_nhap"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label={`${LABEL_KHO} nhập`}
                  required
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO} nhập`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho_nhap?.message}
                  FormAdd={FormKho}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  required
                  label="Trạng thái"
                  apiCode="trangthai"
                  placeholder="Trạng thái phiếu"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{ ma_ct: 'PXC', status: true }}
                  errorMessage={errors?.trang_thai?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
