import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import LogoIcon from '~/assets/img/logo.svg';
import { useSelector } from 'react-redux';

function Logo() {
  const { data: enterprise } = useSelector((state) => state.enterprise);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="5px"
      sx={{ height: '50px' }}
    >
      <Avatar src={LogoIcon} sx={{ width: '50px', height: '50px' }} />
      <Stack>
        <Typography
          sx={{
            textDecoration: 'none',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '20px',
          }}
        >
          POS PLUS
        </Typography>
        {enterprise?.name && (
          <Typography
            title={enterprise.name}
            sx={{
              fontSize: '12px',
              lineHeight: '14px',
              maxWidth: '250px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {enterprise.name}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default Logo;
