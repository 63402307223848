import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';
import { MdAddCircleOutline } from 'react-icons/md';
import FormThongTinGiaoHang from '~/components/form/thongtingiaohang/FormThongTinGiaoHang';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';
import { BsTrash } from 'react-icons/bs';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';

function Thongtingiaohang() {
  const showAlert = useAlertContext();
  const { asyncGetList, asyncDelete } = useApisContext();
  const { handleUpdateCurrentHd } = useOrderPblContext();
  const { currentHd } = useSelector((state) => state.orderPbl);
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [load, setLoad] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleItemClick = (data) => {
    setDefaultValues(data);
    setOpenForm(true);
    setIsEdit(true);
  };

  const handleDeleteItem = async (data) => {
    try {
      await asyncDelete({ apiCode: 'thongtingiaohang', uniqueValue: data._id });
      if (data._id === currentHd?.exfields?.thong_tin_giao_hang?._id) {
        handleUpdateCurrentHd({
          exfields: {
            ...(currentHd?.exfields || {}),
            thong_tin_giao_hang: null,
          },
        });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message:
          error?.error || error?.message || 'Lỗi khi xóa thông tin giao hàng',
      });
    } finally {
      setLoad(load + 1);
    }
  };

  const handleThongtingiaohangChange = (_, value) => {
    const ttgh = (data || []).find((item) => item._id === value);
    if (!ttgh) {
      showAlert({
        type: 'error',
        message: 'Thông tin giao hàng không tồn tại',
      });
      return;
    }
    handleUpdateCurrentHd({
      exfields: { ...(currentHd?.exfields || {}), thong_tin_giao_hang: ttgh },
    });
  };

  const getData = async () => {
    try {
      setLoading(true);
      const resp = await asyncGetList({
        apiCode: 'thongtingiaohang',
        condition: { page: 1, limit: 99999, q: { ma_kh: currentHd.ma_kh } },
      });
      if (isArray(resp)) {
        setData(resp);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.error || error?.message || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const renderLoading = () => {
    return (
      <Stack gap={1}>
        {new Array(2).fill(0).map((_, index) => {
          return (
            <Stack key={index} direction="row" alignItems="center" gap={2}>
              <Skeleton
                variant="circular"
                sx={{ width: '20px', height: '20px' }}
              />
              <Stack sx={{ flex: 1 }}>
                <Skeleton
                  variant="text"
                  sx={{ width: '40%' }}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  sx={{ width: '100%' }}
                  animation="wave"
                />
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.ma_kh, load]);

  if (!currentHd?.ma_kh) {
    return null;
  }

  return (
    <>
      {openForm && (
        <FormThongTinGiaoHang
          open={openForm}
          defaultValues={
            defaultValues || {
              ma_kh: currentHd?.ma_kh,
              ten_kh: currentHd?.ten_kh,
            }
          }
          isEdit={isEdit}
          handleClose={() => setOpenForm(false)}
          setLoad={setLoad}
        />
      )}
      <Box sx={{ padding: '0 2px' }}>
        <Paper sx={{ p: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>
              Thông tin giao hàng
            </Typography>
            <IconButton
              onClick={() => {
                setOpenForm(true);
                setDefaultValues(null);
                setIsEdit(false);
              }}
            >
              <MdAddCircleOutline size={16} />
            </IconButton>
          </Stack>
          {loading ? (
            renderLoading()
          ) : (
            <>
              {data?.length > 0 ? (
                <RadioGroup
                  key={data._id}
                  value={currentHd?.exfields?.thong_tin_giao_hang?._id || ''}
                  onChange={handleThongtingiaohangChange}
                  sx={{ gap: 1 }}
                >
                  {data?.map((item) => {
                    return (
                      <Item
                        key={item._id}
                        data={item}
                        onItemClick={handleItemClick}
                        onDeleteItem={handleDeleteItem}
                      />
                    );
                  })}
                </RadioGroup>
              ) : (
                <Typography sx={{ textAlign: 'center' }}>
                  Chưa có thông tin giao hàng
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Box>
    </>
  );
}

export default Thongtingiaohang;

function Item({ data, onItemClick, onDeleteItem }) {
  const showConfirm = useConfirmContext();

  return (
    <MenuItem
      sx={{
        border: '1px dashed',
        borderColor: 'divider',
        p: 1,
        borderRadius: '10px',
      }}
    >
      <Stack>
        <Radio size="small" value={data?._id} />
        <IconButton
          sx={{ color: 'error.main' }}
          onClick={() => {
            showConfirm({
              title: 'Xác nhận xóa',
              content: (
                <Box sx={{ padding: '0 10px' }}>
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    Bạn có chắc muốn xóa thông tin này không ?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontStyle: 'italic',
                      color: 'primary.main',
                      marginTop: '10px',
                    }}
                  >
                    Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                  </Typography>
                </Box>
              ),
              onConfirm: () => onDeleteItem(data),
            });
          }}
        >
          <BsTrash size={14} />
        </IconButton>
      </Stack>
      <Stack
        onClick={() => onItemClick(data)}
        sx={{
          '&:hover p': {
            color: 'primary.main',
          },
        }}
      >
        <Typography sx={{ whiteSpace: 'normal' }}>
          {data?.exfields?.ten_nguoi_nhan} - {data?.dien_thoai}
        </Typography>
        <Typography sx={{ whiteSpace: 'normal' }}>
          Địa chỉ: {data?.dia_chi}, {data?.ten_xa_phuong},{' '}
          {data?.ten_quan_huyen}, {data?.ten_tinh_thanh}
        </Typography>
      </Stack>
    </MenuItem>
  );
}
