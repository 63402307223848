import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import CheckboxInput from '~/components/input/CheckboxInput';
import TextInput from '~/components/input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';

function InfoSection({ isEdit, register, control, errors }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              readOnly={isEdit}
              label={`Mã ${LABEL_KHO}`}
              placeholder="kcty"
              name="ma_kho"
              register={register}
              required
              errorMessage={errors?.ma_kho?.message}
            />
            <TextInput
              labelWidth="30%"
              label={`Tên ${LABEL_KHO}`}
              placeholder="Chi nhánh trung tâm"
              name="ten_kho"
              register={register}
              required
              errorMessage={errors?.ten_kho?.message}
            />
            {isEdit && (
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Còn hoạt động"
                    name="status"
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              label="Điện thoại"
              placeholder="Số điện thoại"
              name="dien_thoai"
              register={register}
              errorMessage={errors?.dien_thoai?.message}
            />
            <TextInput
              label="Email"
              placeholder="Email liên hệ"
              name="email"
              register={register}
              errorMessage={errors?.email?.message}
            />

            {/* <TextInput
              label="Địa chỉ"
              placeholder="Địa điểm"
              name="dia_chi"
              register={register}
            /> */}
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
