import React from 'react';
import ModalBase from './ModalBase';

function BillLandModal({ open, onClose }) {
  return (
    <ModalBase
      width="800px"
      title="Tạo vận đơn"
      open={open}
      handleClose={onClose}
    ></ModalBase>
  );
}

export default BillLandModal;
