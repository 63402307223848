import React from 'react';
import AdminLayout from '~/components/layouts/AdminLayout';
import { Grid, Stack, Typography } from '@mui/material';
import ResultSellToday from './components/ResultSellToday';
import useResponsive from '~/hooks/useResponsive';
import DoanhThuThang from './components/DoanhThuThang';
import HangBanChay from './components/HangBanChay';

function HomePage() {
  const matches = useResponsive({ matchKey: 'up', breakpoint: 'md' });

  return (
    <AdminLayout>
      <Grid
        container
        spacing="10px"
        sx={{
          padding: '10px 0',
          alignItems: 'stretch',
          height: matches ? 'calc(100vh - 50px - 42px)' : 'calc(100vh - 50px)',
        }}
      >
        <Grid
          item
          xs={12}
          md={9}
          className="hidden-scroll"
          sx={{ height: '100%', overflow: 'auto', paddingRight: '5px' }}
        >
          <Stack gap={2}>
            <ResultSellToday />
            <DoanhThuThang />
            <HangBanChay />
          </Stack>
        </Grid>
        {!!matches && (
          <Grid item xs={0} md={3} sx={{ height: '100%' }}>
            <Stack gap={2}>
              <Stack
                direction="row"
                sx={{
                  paddingBottom: '10px',
                  borderBottom: '1px solid #ededed',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Các hoạt động gần đây
                </Typography>
              </Stack>
              <Stack
                spacing={'10px'}
                className="custome-scrolly"
                sx={{
                  maxHeight: 'calc(100vh - 50px - 42px - 10px - 20px - 32px)',
                  overflow: 'auto',
                  padding: '5px 5px 5px 0',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'italic',
                    textAlign: 'center',
                  }}
                >
                  Chưa có hoạt động
                </Typography>
                {/* {Array(2)
                  .fill(0)
                  .map((item, index) => (
                    <Stack key={index} spacing={1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="flex-start"
                        sx={{
                          backgroundColor:
                            index === 0 ? 'secondary.fif' : '#ededed',
                          padding: '5px',
                          borderRadius: '6px',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'secondary.fif',
                          },
                        }}
                      >
                        <Avatar
                          src={PaymentIcon}
                          sx={{ width: 40, height: 40, borderRadius: 0 }}
                        />
                        <Box>
                          <Typography sx={{ fontSize: '12px' }}>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: 'secondary.main',
                              }}
                            >
                              Ngọc Ánh - Kinh Doanh
                            </Typography>
                            <Typography
                              component="span"
                              sx={{ fontSize: '12px' }}
                            >
                              {' '}
                              vừa
                            </Typography>
                            <Typography
                              component="span"
                              sx={{ fontSize: '12px', color: 'secondary.main' }}
                            >
                              {' '}
                              bán đơn hàng
                            </Typography>
                            <Typography
                              component="span"
                              sx={{ fontSize: '12px' }}
                            >
                              {' '}
                              với giá trị
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: 'secondary.main',
                              }}
                            >
                              {' '}
                              150.000
                            </Typography>
                          </Typography>
                          <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                            5 phút trước
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  ))} */}
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </AdminLayout>
  );
}

export default HomePage;
