import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import FilterRadios from '~/components/filter/FilterRadios';
import { useSelector } from 'react-redux';
import { useOrderPblContext } from '~/pages/order-pbl/OrderPblPage';

function Pttt() {
  const { currentHd } = useSelector((state) => state.orderPbl);
  const ptttData = useSelector((state) => state.pttt);
  const { handleUpdateCurrentHd } = useOrderPblContext();

  const handlePtttChange = (id) => {
    const val = (ptttData?.data || []).find((item) => item._id === id);
    if (!val) return;
    const tyLeCk = Number(val.kieu_so1 || 0);
    let tyLeCkHd =
      (currentHd?.ty_le_ck_hd || 0) - (currentHd?.exfields?.ty_le_ck_pttt || 0);
    if (tyLeCkHd < 0) {
      tyLeCkHd = 0;
    }
    const dataChange = {
      pt_thanh_toan: val._id,
      ten_ht_thanh_toan: val.ten,
      tk_no: val?.tk_cn,
      ty_le_ck_hd: +tyLeCkHd + tyLeCk,
      exfields: { ...(currentHd?.exfields || {}), ty_le_ck_pttt: tyLeCk },
    };
    handleUpdateCurrentHd(dataChange);
  };

  const pttts = useMemo(() => {
    if (!ptttData?.data) {
      return [];
    }
    const result = ptttData?.data.reduce((acc, item) => {
      return [...acc, { label: item.ten, value: item._id, ...item }];
    }, []);
    return result;
  }, [ptttData?.data]);

  return (
    <Box sx={{ padding: '0 2px' }}>
      <FilterRadios
        title="Hình thức thanh toán"
        values={pttts}
        showActive={false}
        defaultValue={currentHd?.pt_thanh_toan || ''}
        onChange={handlePtttChange}
      />
    </Box>
  );
}

export default Pttt;
