import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import AdminLayout from '~/components/layouts/AdminLayout';
import useApisContext from '~/hooks/hookContext/useApisContext';
import EnterpriseImage from '~/assets/img/enterprise.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  updateEnterprise,
  updateIdApp,
} from '~/redux/reducrers/enterprise.reducer';
import FilterEnterprise from '~/components/filter/enterprise/FilterEnterprise';
import { resetHd } from '~/redux/reducrers/order-pbl.reducer';

function EnterprisePage() {
  const { callApi } = useApisContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [condition, setCondition] = useState({});

  const [enterprises, setEnterprises] = useState([]);

  const getEnterprises = async () => {
    const resp = await callApi({
      method: 'get',
      endpoint: `/app?q=${JSON.stringify(condition)}`,
    });
    if (resp && resp.length > 0) {
      setEnterprises(resp);
    }
  };

  const handleClickEnterprise = (enterprise) => {
    dispatch(updateEnterprise(enterprise));
    dispatch(updateIdApp(enterprise._id));
    dispatch(resetHd());
    navigate('/');
  };

  useEffect(() => {
    getEnterprises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return (
    <AdminLayout>
      <Box
        className="hidden-scroll"
        sx={{ height: '100%', overflow: 'auto', padding: '10px 0' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={2.5}>
            <FilterEnterprise setCondition={setCondition} />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Typography
              component="h1"
              sx={{ fontSize: '20px', fontWeight: 500 }}
            >
              Danh sách công ty
            </Typography>
            <Box>
              <Grid container>
                {enterprises?.length > 0 &&
                  enterprises.map((enterprise) => (
                    <Grid key={enterprise._id} item xs={12} sm={3}>
                      <Box sx={{ padding: '5px' }}>
                        <Paper
                          component={MenuItem}
                          sx={{ width: '100%', padding: '10px' }}
                          onClick={() => handleClickEnterprise(enterprise)}
                        >
                          <Stack
                            alignItems="center"
                            spacing="5px"
                            sx={{ width: '100%' }}
                          >
                            <Avatar
                              src={EnterpriseImage}
                              alt="enterprise image"
                              sx={{
                                borderRadius: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: '14px',
                                width: '100%',
                                whiteSpace: 'normal',
                              }}
                            >
                              {enterprise.name}
                            </Typography>
                          </Stack>
                        </Paper>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
}

export default EnterprisePage;
