import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';

function FilterDVVC({ setCondition }) {
  const originFilter = {
    dvvc: '',
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.dvvc) {
      condition.$or = [
        {
          ma: {
            $regex: filter.dvvc.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten: {
            $regex: filter.dvvc.split(' ').join('.*'),
            $options: 'i',
          },
        },
      ];
    }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.dvvc}
          onSearch={(value) => setFilter({ ...filter, dvvc: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDVVC;
