import React, { useState } from 'react';
import { Box, Container, Stack } from '@mui/material';
import Menu from './Menu';
import ListBill from './ListBill';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useOrderContext } from '../../OrderPage';
import { v4 } from 'uuid';
import SelectStore from '~/components/input/SelectStore';
import SearchAndSelectInput from '~/components/input/SearchAndSelectInput';
import DvtsModal from '~/components/modal/DvtsModal';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { isArray } from 'lodash';

function Header({ headerHeight }) {
  const { currentHd } = useSelector((state) => state.order);
  const { currentStore } = useSelector((state) => state.store);
  const { handleUpdateCurrentHd } = useOrderContext();
  const { asyncGetList } = useApisContext();
  const [openDvts, setOpenDvts] = useState(false);
  const [dvts, setDvts] = useState([]);

  const handleAddProductToDetails = ({
    product,
    gia_ban_le,
    ma_dvt,
    ten_dvt,
  }) => {
    const { ma_vt, ten_vt, gia_mua, thue_suat_nk, ma_nvt, ten_nvt, exfields } =
      product;
    const giaBan = gia_ban_le;
    const giaBanLe = Number((gia_ban_le / (1 + thue_suat_nk / 100)).toFixed(0));
    // const tienThue = giaBan * ((thue_suat_nk || 0) / 100);
    const tienHang = giaBan;
    const tienCk = 0;
    const tienXuat = tienHang - tienCk;
    const detailData = {
      _id: v4(),
      dien_giai: '',
      gia_ban_le: giaBanLe,
      gia_ban: giaBan,
      gia_ban_nt: giaBan,
      gia_von: gia_mua || 0,
      gia_von_nt: gia_mua || 0,
      ma_dvt,
      ten_dvt,
      ma_nvt,
      ten_nvt,
      ma_kho: currentStore?.ma_kho || '',
      ten_kho: currentStore?.ten_kho || '',
      nh_hoa_hong: product?.nh_hoa_hong || '',
      ten_nh_hoa_hong: product?.ten_nh_hoa_hong || '',
      ma_lo: '',
      han_sd: null,
      ma_vt,
      ten_vt,
      sl_xuat: 1,
      so_luong: 1,
      tien: tienHang,
      tien_nt: tienHang,
      tien_ck: 0,
      tien_ck_nt: 0,
      // thue_suat: thue_suat_nk || 0,
      tien_xuat: tienXuat,
      tien_xuat_nt: tienXuat,
      tk_ck: '52111',
      tk_dt: '51111',
      tk_gv: '6321',
      tk_vt: '1561',
      ty_le_ck: 0,
      hoa_don: exfields?.hoa_don || false,
      ds_tem: exfields?.dmgiaban ? [JSON.stringify(exfields.dmgiaban)] : [],
    };

    const currentHdClone = cloneDeep(currentHd);
    currentHdClone.details.push(detailData);
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleSelectProduct = async (product) => {
    const { ma_vt, ma_dvt } = product;
    const respDvts = await asyncGetList({
      apiCode: 'dmqddvt',
      condition: {
        page: 1,
        limit: 99999,
        q: { ma_vt, ma_dvt: { $ne: ma_dvt } },
      },
    });
    if (isArray(respDvts) && respDvts.length > 0) {
      setDvts(() => {
        return respDvts.reduce(
          (acc, item) => {
            return [
              ...acc,
              {
                ...product,
                ma_dvt: item.ma_dvt,
                ten_dvt: item.ten_dvt,
                gia_ban_le: item.gia_ban_nt,
              },
            ];
          },
          [{ ...product }]
        );
      });
      setOpenDvts(true);
    } else {
      handleAddProductToDetails({
        product,
        gia_ban_le: product?.gia_ban_le || 0,
        ma_dvt: product?.ma_dvt || 0,
        ten_dvt: product?.ten_dvt || 0,
      });
    }
  };

  return (
    <>
      <DvtsModal
        open={openDvts}
        onClose={() => setOpenDvts(false)}
        dvts={dvts}
        handleAddProductToDetails={handleAddProductToDetails}
      />
      <Box
        component="header"
        sx={{
          width: '100%',
          height: headerHeight,
          backgroundColor: 'primary.main',
        }}
      >
        <Container maxWidth="xl" sx={{ height: '100%', width: '100%' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: '100%', width: '100%' }}
          >
            <Stack sx={{ flex: 1 }} direction="row" alignItems="center">
              <Box sx={{ width: '400px' }}>
                {/* <SearchProducts onSelect={handleSelectProduct} /> */}
                <SearchAndSelectInput onSelect={handleSelectProduct} />
              </Box>
              <Box sx={{ width: '200px', marginLeft: '10px' }}>
                <ListBill />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" spacing="5px">
              <SelectStore />
              <Menu />
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Header;
