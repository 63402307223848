import React, { memo } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import ButtonBase from '~/components/button/ButtonBase';
import { BsCaretLeft, BsCaretRight, BsPlusCircle } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { useBillContext } from '../../Bill';
import './style.css';
import { useState } from 'react';
import ModalConfirm from '~/components/modal/ModalConfirm';
import useResponsive from '~/hooks/useResponsive';

function ListBill() {
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const smMatches = useResponsive({ matchKey: 'up', breakpoint: 'sm' });
  const { pbhs, pbhSelected, createPbh, setPbhSelected, updatePbh } =
    useBillContext();
  const [openConfirm, setOpenConFirm] = useState(false);

  const handleDeleteBill = async () => {
    const pbhDelete = {
      ...pbhSelected,
      trang_thai: '9',
      ten_trang_thai: 'Hủy',
    };
    await updatePbh(pbhDelete);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="5px"
      sx={{ marginLeft: mdMatches ? '20px' : 0 }}
    >
      <ModalConfirm
        open={openConfirm}
        title="Xác nhận"
        handleClose={() => setOpenConFirm(false)}
        onConfirm={handleDeleteBill}
      >
        <Typography
          sx={{ fontSize: '14px', fontStyle: 'italic', textAlign: 'center' }}
        >
          Bạn có chắc muốn xóa hóa đơn này không ?
        </Typography>
      </ModalConfirm>
      <Tooltip placement="top" arrow title="Thêm hóa đơn">
        <IconButton onClick={createPbh} sx={{ color: 'common.white' }}>
          <BsPlusCircle size={14} />
        </IconButton>
      </Tooltip>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: smMatches ? '252px' : '146px',
          height: '34px',
          position: 'relative',
          padding: pbhs?.length > 2 ? '0 20px' : '0',
        }}
      >
        <Swiper
          style={{ width: '100%' }}
          slidesPerView={smMatches ? 2 : 1}
          spaceBetween={5}
          grabCursor
          navigation={{
            prevEl: '.btn-prev-bill',
            nextEl: '.btn-next-bill',
            disabledClass: 'disable-btn',
          }}
          modules={[Navigation]}
        >
          {pbhs?.map((pbh, index) => {
            const active = pbh._id === pbhSelected?._id;
            return (
              <SwiperSlide key={pbh._id} style={{ width: 'fit-content' }}>
                <ButtonBase
                  onClick={() => setPbhSelected(pbh)}
                  sx={{
                    width: '100%',
                    height: '30px',
                    backgroundColor: 'common.white',
                    color: active ? 'primary.main' : 'common.black',
                    '&:hover': {
                      backgroundColor: 'common.white',
                    },
                  }}
                  endIcon={
                    active && pbhs?.length > 1 ? (
                      <Tooltip title="Hủy" placement="top" arrow>
                        <Box onClick={() => setOpenConFirm(true)}>
                          <MdClose size="12px" />
                        </Box>
                      </Tooltip>
                    ) : null
                  }
                  variant="contained"
                >
                  Hóa đơn {index + 1}
                </ButtonBase>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <IconButton
          sx={{
            display: pbhs.length > (smMatches ? 2 : 1) ? 'inline-flex' : 'none',
            position: 'absolute',
            top: '50%',
            left: '0px',
            transform: 'translate(-40%, -50%)',
            color: 'common.white',
          }}
          className="btn-prev-bill"
        >
          <BsCaretLeft size="14px" />
        </IconButton>
        <IconButton
          sx={{
            display: pbhs.length > (smMatches ? 2 : 1) ? 'inline-flex' : 'none',
            position: 'absolute',
            top: '50%',
            right: '0px',
            transform: 'translate(40%, -50%)',
            color: 'common.white',
          }}
          className="btn-next-bill"
        >
          <BsCaretRight size="14px" />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default memo(ListBill);
